import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/grid"
import "./style.css"

import React from "react"
import ReactDOM from "react-dom/client"
import { StrictMode, Suspense } from "react"
import { Router } from "react-router-dom"
import { createBrowserHistory } from "history"
import { QueryClient, QueryClientProvider, useQuery } from "react-query"

// import { Leva } from "leva"

// import { Canvas } from "@react-three/fiber"
// import { Preload } from "@react-three/drei"

import { App3D } from "./app3D/App3D"
import { App2D } from "./app2D/App2D"

import "./i18n"

const history = createBrowserHistory({ window })
const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.querySelector("#root"))

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <LoginCheck>
        <BrowserRouter history={history}>
          <Suspense>
            <App3D />
          </Suspense>
          <App2D />
        </BrowserRouter>
      </LoginCheck>
    </QueryClientProvider>
  </StrictMode>,
)

function BrowserRouter({ children, history }) {
  let [state, dispatch] = React.useReducer((_, action) => action, {
    action: history.action,
    location: history.location,
  })

  React.useLayoutEffect(() => history.listen(dispatch), [history])

  return (
    <Router
      children={children}
      action={state.action}
      location={state.location}
      navigator={history}
    />
  )
}

function LoginCheck({ children }) {
  // http://localhost:3000/api/login

  const { isLoading, error, data } = useQuery("login", () =>
    fetch("/api/login").then((res) => res.json()),
  )

  // console.log("data", data)
  console.log("error", error)

  function redirect() {
    // window.location.href = "https://space.springpod.com/sign-in"
    window.location.href =
      "https://space.springpod.com/sign-in?redirectTo=https://explore.rhode-island.springpod.com/"
    // console.log("redirecting to login page")
  }

  if (isLoading) return "Loading..."
  if (error) {
    redirect()
    return "An error has occurred: " + error.message
  }

  if (!data.check.data?.getStudent.emailVerified) {
    redirect()
  } else {
    console.log("(!) user check passed")
  }

  return <>{children}</>
}
