import { Ocean } from "./Ocean"
import { Site } from "./Site"
import { useTexture, useVideoTexture } from "@react-three/drei"

export function Defense({ ktx2Loader, dracoLoader }) {
  /*
   * properties
   */

  // textures and videos
  // const test_screen_bars = useTexture("/textures/test_screen_bars.jpg")
  // const video1 = useVideoTexture("/video/glitch.mp4")
  const uv_test = useTexture("/textures/uv_test.jpg")

  // link textures and/or video's to model nodes
  const screenTextures = {
    Screen_Info_Point_Lvl01: uv_test,
    Screen_Main_Lvl01: uv_test,
  }

  /*
   * init: run Site specific code after the modal has been loaded
   */

  const init = (model, animations) => {
    // console.log('Defense :: init');

    // console.log('model');
    // console.log(model);

    // console.log('animations');
    // console.log(animations);

    const action = animations.actions["Animation"]
    action.play()

    model.nodes.Sea.visible = false
  }

  /*
   * visuals
   */

  return (
    <group>
      <Site
        name="defense"
        cameraOrder={[0, 1, 2, 3]}
        cameraScale={0.2}
        focusDistance={0.04}
        focusLength={0.04}
        init={init}
        // url={'/models/defense/01_Defense_006.glb.glb'}
        // url={'/models/defense/resize.glb'}

        // url={"/models/defense/resize-ktx-ETC1S-draco.glb"}
        url={"/models/defense/resize-ktx-ETC1S-draco.glb"}
        // url={"/models/defense/01_Defense_009_Screen_UVs_Updated.glb"}

        // url={'/models/defense/resize-ktx-ETC1S.glb'}
        screenTextures={screenTextures}
        ktx2Loader={ktx2Loader}
        dracoLoader={dracoLoader}
      />
      <Ocean />
    </group>
  )
}
